<template>
  <div class="trade-in-special">
    <div
      id="realisteWidget"
      data-widget="https://zk-mainstreet.realiste.ai/"
      class="realisteWidget"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TradeInSpecialPage",
  metaInfo: {
    meta: [
      {
        name: "description",
        content: "",
      },
    ],
  },
  created() {
    let realisticWidgetScript = document.createElement("script");
    realisticWidgetScript.setAttribute(
      "src",
      "https://script.realiste-widget.ru/widget-init.js"
    );
    realisticWidgetScript.setAttribute("id", "realisticWidgetScript");
    document.body.appendChild(realisticWidgetScript);
  },
  beforeDestroy() {
    const realisticWidgetScript = document.querySelector(
      "#realisticWidgetScript"
    );
    document.body.removeChild(realisticWidgetScript);
  },
};
</script>

<style lang="scss" scoped>
.trade-in-special {
  background: #f4f4f4;
  &-block {
    padding-bottom: 40px;
  }

  .realisteWidget {
    background: #f4f4f4;
  }
}
</style>
